import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { alt } from '../../graphics'
import { amountFormatter, dateFormatterEN } from '../../utils/formatter'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginBottom: '1rem',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '60rem',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  checkbox: {
    width: 70,
    height: 70,
  },
}))

export default function EventCard({
  event,
  checked,
  onSelected,
  onUnSelected,
  onDelete = null,
  onEdit = null,
}) {
  const classes = useStyles()

  const image = event.img_thumbnail
    ? `https://api.amiv.ethz.ch${event.img_thumbnail.file}`
    : alt

  const title = event.title_en ? event.title_en : event.title_de
  const { location } = event
  const price = amountFormatter(event.price)
  const start_time = dateFormatterEN(
    event.time_start,
    'dddd, mmmm dS yyyy, HH:MM'
  )
  const catchphrase = event.catchphrase_en || event.catchphrase_de
  const description = event.description_en || event.description_de

  return (
    <Card
      className={classes.root}
      onClick={() => {
        if (checked) {
          onUnSelected()
        } else {
          onSelected()
        }
      }}
      style={{ cursor: 'pointer' }}
    >
      <CardMedia
        className={classes.cover}
        image={event.image_base64 ? event.image_base64 : image}
        title="Event image"
      />
      <div className={classes.controls}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography component="h5" variant="h5">
              {title}{' '}
              <Typography
                component="p"
                variant="subtitle1"
                color="textSecondary"
              >
                [{catchphrase}]
              </Typography>
            </Typography>
            <Breadcrumbs>
              <Typography variant="subtitle1" color="textSecondary">
                {start_time}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {typeof event.price === 'number' ? `CHF ${price}` : event.price}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {location}
              </Typography>
            </Breadcrumbs>
            <Typography variant="body2" component="p" noWrap>
              {description}
            </Typography>
          </CardContent>
        </div>
        {onDelete ? <DeleteIcon onClick={onDelete} /> : null}
        {onEdit ? <EditIcon onClick={onEdit} /> : null}
        <Checkbox
          checked={checked}
          value="secondary"
          color="primary"
          className={classes.checkbox}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          onChange={e => {
            if (e.target.checked) {
              onSelected(e)
            } else {
              onUnSelected(e)
            }
          }}
        />
      </div>
    </Card>
  )
}

EventCard.propTypes = {
  /* Event provided as is from AMIV-API */
  event: PropTypes.object,
  checked: PropTypes.bool,
  onSelected: PropTypes.func,
  onUnSelected: PropTypes.func,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
}
