import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import WarningIcon from '@material-ui/icons/Warning'
import Alert from '@material-ui/lab/Alert'

import Header from './header/header'

const useStyles = makeStyles(theme => ({
  '@global *': {
    transition: 'background .125s ease',
  },
  '@global a': {
    color: theme.palette.secondary.main,
  },
  root: {
    width: '100%',
    minHeight: '100vh',
    display: 'grid',
    gridTemplateRows: 'auto auto 1fr auto',
    gridTemplateAreas: "'header' 'jswarning' 'content' 'footer'",
    background: theme.palette.background.default,
  },
}))

const Layout = ({ className, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {/*
       CssBaseline kickstart an elegant, consistent, and simple baseline to build upon.
       This line must not be in themeContext! Otherwise, server-side rendering does not work correctly.
      */}
      <CssBaseline />
      <Header className={classes.header} />
      <noscript>
        <div className={classes.jsWarning}>
          <Alert icon={<WarningIcon />} severity="error">
            Javascript disabled
          </Alert>
        </div>
      </noscript>
      <main className={[classes.content, className].join(' ')}>{children}</main>
      {/* <Footer className={classes.footer} /> */}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default Layout
