import FieldSelection from '../../pages/FieldSelection'
import Sorting from '../../pages/Sorting'
import Finalize from '../../pages/Finalize'
import Preview from '../../pages/Preview'

export const menuItems = [
  {
    label: 'Field Selection',
    path: '/selection',
    component: FieldSelection,
    needslogin: false,
  },
  {
    label: 'Sorting',
    path: '/sorting',
    component: Sorting,
    needslogin: false,
  },
  {
    label: 'Preview',
    path: '/preview',
    component: Preview,
    needslogin: false,
  },
  {
    label: 'Finalize',
    path: '/finalize',
    component: Finalize,
    needslogin: true,
  },
]
