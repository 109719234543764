import React, { useState, useEffect } from 'react'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import axios from 'axios'
import TextField from '@material-ui/core/TextField'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import prep_announce from '../utils/announce_prepare'
import { announceApiUrl } from '../config'
import { get } from '../components/newComponents/localStorage'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(6, 0, 3),
    alignContent: 'center',
  },
}))

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Finalize = () => {
  const [mustacheRender, updateMustacheRender] = useState(
    'Mustache Render incoming'
  )
  const [mustacheTxt, updateMustacheTxt] = useState('Mustache Txt incoming')
  const [isLoading, setIsLoading] = useState()
  const [subject, set_subject] = useState(null)
  const [success, setSuccess] = React.useState(false)
  const [failure, setFailure] = React.useState(false)

  const session = axios.create({
    baseURL: announceApiUrl,
    headers: { 'Content-Type': 'application/json' },
    validateStatus: () => true,
  })

  useEffect(() => {
    const renderMustache = async () => {
      setIsLoading(true)

      await prep_announce(updateMustacheRender, updateMustacheTxt)

      setIsLoading(false)
    }
    renderMustache()
  }, [])

  const send = () => {
    const formData = new FormData()
    formData.append('html', mustacheRender)
    formData.append('text', mustacheTxt)
    formData.append('token', get('token'))
    formData.append('sub', `${subject} [AMIV Announce]`)

    session
      .post('/mailer', formData)
      .then(() => setSuccess(true))
      .catch(e => {
        setFailure(e.message)
        setSuccess(false)
        console.log(e)
      })
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSuccess(false)
    setFailure(false)
  }

  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <h1>Finalize</h1>
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div>
          <TextField
            placeholder={subject}
            margin="dense"
            label="Announce Subject"
            type="text"
            onChange={evt => set_subject(evt.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={send}
            disabled={!subject}
          >
            Send
          </Button>
        </div>
      )}

      <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Successfully submitted!
        </Alert>
      </Snackbar>

      <Snackbar open={failure} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Error sending!
          {`\n${failure}`}
        </Alert>
      </Snackbar>
    </Container>
  )
}

export default Finalize
