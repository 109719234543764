import { get } from '../components/newComponents/localStorage'
import AnnounceRenderer from './announceRenderer'
import { announce_txt } from './announceRenderer_modern'

export default async (set_html, set_txt) => {
  const selectedItems = get('order') ? JSON.parse(get('order')) : null
  const featuredItems = get('featured') ? JSON.parse(get('featured')) : null
  const message = get('message') || null

  const customEntries = get('custom_entries')
    ? JSON.parse(get('custom_entries'))
    : null

  const announce = await AnnounceRenderer(
    [...selectedItems],
    [...featuredItems],
    customEntries,
    message
  )
  set_html(announce)

  const announcetxt = await announce_txt(
    [...selectedItems],
    [...featuredItems],
    customEntries,
    message
  )
  set_txt(announcetxt)
}
