import dateFormat from 'dateformat'

const amountFormatter = price => {
  if (price == null || price === 0) {
    return 'FREE'
  }
  const rappen = price % 100
  const franken = Math.floor(price / 100)
  return `CHF ${franken}.${rappen >= 10 ? rappen : `0${rappen}`}`
}

const dateFormatterEN = (date, format) => {
  dateFormat.i18n = {
    dayNames: [
      'Sun',
      'Mon',
      'Tue',
      'Wed',
      'Thu',
      'Fri',
      'Sat',
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    monthNames: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'],
  }
  return dateFormat(date, format)
}

const dateFormatterDE = (date, format) => {
  dateFormat.i18n = {
    dayNames: [
      'So',
      'Mo',
      'Di',
      'Mi',
      'Do',
      'Fr',
      'Sa',
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag',
    ],
    monthNames: [
      'Jan',
      'Feb',
      'Mär',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dez',
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    timeNames: ['a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'],
  }
  return dateFormat(date, format)
}

const linkFormatterDE = (eventId) => {
  return `https://amiv.ethz.ch/de/events/${eventId}`
}

const linkFormatterEN = (eventId) => {
  return `https://amiv.ethz.ch/en/events/${eventId}`
}

export { amountFormatter, dateFormatterDE, dateFormatterEN, linkFormatterDE, linkFormatterEN }
