import PropTypes from 'prop-types'
import React from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { makeStyles } from '@material-ui/core/styles'
import TinyEventCard from '../newComponents/tinyEventCard'

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = 2

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // styles we need to apply on draggables
  ...draggableStyle,
})

const useListStyle = makeStyles({
  '@keyframes example': {
    '0%': { background: 'red' },
    '25%': { background: 'yellow' },
    '50%': { background: 'deeppink' },
    '100%': { background: 'green' },
  },
  default: {
    background: 'lightgrey',
    padding: grid,
    width: 'auto',
    'border-radius': '5px',
  },
  dragging: {
    animation: `$example 4s linear 200ms infinite`,
  },
})

const EventDNDList = ({ items, onReordered, onSelected, onUnSelected }) => {
  const classes = useListStyle()
  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }
    const _items = reorder(items, result.source.index, result.destination.index)

    onReordered(_items)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={[
              classes.default,
              snapshot.isDraggingOver ? classes.dragging : null,
            ].join(' ')}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {/* eslint-disable-next-line no-shadow */}
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <TinyEventCard
                      title={item.title}
                      date={item.date}
                      image_url={item.image_url}
                      image_base64={item.image_base64 || null}
                      onSelected={() => onSelected(item.id)}
                      onUnSelected={() => onUnSelected(item.id)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default EventDNDList

EventDNDList.propTypes = {
  /* Event provided as is from AMIV-API */
  items: PropTypes.array,
  onReordered: PropTypes.func,
  onSelected: PropTypes.func,
  onUnSelected: PropTypes.func,
}
