import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import CardMedia from '@material-ui/core/CardMedia'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { dateFormatterEN } from '../../utils/formatter'
import { alt } from '../../graphics'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '60rem',
  },
  title: {
    paddingTop: '0.4rem',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    width: '7rem',
    height: '7rem',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
  },
  checkbox: {
    width: 70,
    height: 70,
  },
}))

export default function TinyEventCard({
  title,
  image_url = null,
  image_base64 = null,
  date,
  checked,
  onSelected,
  onUnSelected,
}) {
  const classes = useStyles()

  const image = image_url ? `https://api.amiv.ethz.ch${image_url}` : alt
  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.cover}
        image={image_base64 || image}
        title="Event image"
      />
      <div className={classes.controls}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Typography className={classes.title} component="h6" variant="h6">
              {title}
            </Typography>
            <Typography component="div" variant="body1">
              {dateFormatterEN(date)}
            </Typography>
          </CardContent>
        </div>
        <FormControlLabel
          value="end"
          control={
            <Checkbox
              checked={checked}
              value="secondary"
              color="primary"
              className={classes.checkbox}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              onChange={e => {
                if (e.target.checked) {
                  onSelected(e)
                } else {
                  onUnSelected(e)
                }
              }}
            />
          }
          label={checked ? 'featured' : 'not featured'}
          labelPlacement="end"
        />
      </div>
    </Card>
  )
}

TinyEventCard.propTypes = {
  /* Event provided as is from AMIV-API */
  title: PropTypes.string,
  checked: PropTypes.bool,
  date: PropTypes.string,
  image_url: PropTypes.string,
  image_base64: PropTypes.string,
  onSelected: PropTypes.func,
  onUnSelected: PropTypes.func,
}
