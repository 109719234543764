/* eslint-disable no-bitwise */
import React, { useState, useEffect } from 'react'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { getAnnounceEventsBefore } from '../utils/retrieveEvents'
import { set, get, remove } from '../components/newComponents/localStorage'
import EventCard from '../components/newComponents/eventCard'
import FieldFormDialog from '../components/forms/customFieldForm'

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0
    const v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const FieldSelection = () => {
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  const [date, updateDate] = useState(yesterday)
  const [page, updatePage] = useState(1)
  const [items, updateItems] = useState([])
  const [selected, updateSelected] = useState([])
  const [editing, setEditing] = useState(-1)
  const [isLoading, setIsLoading] = useState(true)
  const [maxPages, setMaxPages] = useState(1)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [entry_value, setEntry] = useState(null)
  const [customEntries, setCustomEntries] = useState(
    JSON.parse(get('custom_entries'))
  )
  const [message, setMessage] = useState('')

  const setNewDate = new_date => {
    updateItems([])
    updateDate(new_date)
    updatePage(1)
  }

  useEffect(() => {
    const fetchEvents = async () => {
      setIsLoading(true)

      const events = await getAnnounceEventsBefore(date, page)
      const { _items } = events

      _items.push(...items)

      updateItems(_items)
      setMaxPages(Math.ceil(events._meta.total / events._meta.max_results))
      setIsLoading(false)
    }
    fetchEvents()
    /* Please bear with the code below, putting items into the dependency array will trigger an infinite loop */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, date])

  useEffect(() => {
    set('selected', selected)
  }, [selected])

  useEffect(() => {
    set('message', message)
  }, [message])

  const selectEvent = id => {
    const selected_clone = [...selected]
    selected_clone.push(id)
    updateSelected(selected_clone)
  }

  const unselectEvent = id => {
    const selected_clone = [...selected]
    updateSelected(selected_clone.filter(_id => _id !== id))
  }

  const handleClickOpen = () => {
    setDialogOpen(true)
    setEntry(false)
  }

  const delete_custom = index => {
    const custom_entries_clone = JSON.parse(JSON.stringify(customEntries))
    const id = custom_entries_clone[index]._id
    custom_entries_clone.splice(index, 1)
    remove(id)
    set('custom_entries', JSON.stringify(custom_entries_clone))
    setCustomEntries(custom_entries_clone)

    if (selected.includes(id)) {
      unselectEvent(id)
    }
  }

  const handleClose = () => {
    setDialogOpen(false)
    setEntry(false)
    if (editing !== -1) {
      setEditing(-1)
    }
  }

  const handleSubmit = formdata => {
    const addFieldsClone = customEntries || []
    const entry_name = `custom_entry-${uuidv4()}`
    addFieldsClone.push({ ...formdata, _id: entry_name })

    set('custom_entries', JSON.stringify(addFieldsClone))

    const selectedClone = JSON.parse(JSON.stringify(selected))
    selectedClone.push(entry_name)

    updateSelected(selectedClone)
    setCustomEntries(addFieldsClone)

    if (editing !== -1) {
      delete_custom(editing)
      setEditing(-1)
    }

    handleClose()
  }

  const edit_custom = index => {
    setEditing(index)
    setEntry(customEntries[index])
    setDialogOpen(true)
  }

  return (
    <Container>
      <h1>Message to Readers</h1>
      <div>
        Attach a message to the readers at the top of the annouce. Leave empty to omit this field.
      </div>
      <TextField
        label="Message"
        multiline
        fullWidth
        style={{ marginBottom: '1rem' }}
        minRows={3}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <h1>Selection</h1>
      <div style={{ marginBottom: '1rem' }}>
        Select the events for the next announce.
      </div>
      <div style={{ marginBottom: '1rem' }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            variant="inline"
            label="Load events after: "
            value={date}
            onChange={setNewDate}
          />
        </MuiPickersUtilsProvider>
        {customEntries && customEntries.length >= 30 ? (
          <Button variant="outlined" color="primary" disabled>
            Error too many Custom Entries
          </Button>
        ) : (
          <Button
            variant="outlined"
            color="primary"
            onClick={() =>
              // eslint-disable-next-line no-alert
              get('token') ? handleClickOpen() : alert('Please log in first')
            }
          >
            Create custom entry
          </Button>
        )}
        <FieldFormDialog
          open={dialogOpen}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          values={entry_value}
        />
      </div>
      {customEntries ? (
        <div>
          <h2>Custom Entries</h2>
          {customEntries.map((entry, i) => (
            <EventCard
              key={`custom_entry-${i}`}
              event={entry}
              checked={selected.includes(entry._id)}
              onSelected={() => selectEvent(entry._id)}
              onUnSelected={() => unselectEvent(entry._id)}
              onDelete={() => delete_custom(i)}
              onEdit={() => edit_custom(i)}
            />
          ))}
        </div>
      ) : null}
      {/* eslint-disable-next-line no-nested-ternary */}
      {isLoading ? (
        <div>Loading ...</div>
      ) : items !== [] ? (
        <div>
          <h2>Events</h2>
          {items.map(item => (
            <EventCard
              key={item._id}
              event={item}
              checked={selected.includes(item._id)}
              onSelected={() => selectEvent(item._id)}
              onUnSelected={() => unselectEvent(item._id)}
            />
          ))}
        </div>
      ) : null}
      {maxPages > page ? (
        <Button
          variant="contained"
          color="primary"
          onClick={() => updatePage(page + 1)}
        >
          Load more events
        </Button>
      ) : (
        ''
      )}
    </Container>
  )
}

export default FieldSelection
