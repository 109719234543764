import axios from 'axios'
import Mustache from 'mustache'
import { getEventsByIDs } from './retrieveEvents'
import { amountFormatter, dateFormatterDE, dateFormatterEN } from './formatter'
import { alt, announce_logo } from '../graphics'
import ownUrl from './own_url'

// TODO remove Markdown !!!
const renderElement = async (session, element, data = {}) => {
  const rendered = await Mustache.render(
    (await session.get(`${element}.mustache`)).data,
    { ...data, alt }
  )
  return rendered
}

const default_session = axios.create({
  baseURL: `${ownUrl()}/templates`,
  validateStatus: () => true,
})

const eventToDE = events =>
  events.map(event => {
    const newEvent = { ...event }
    newEvent.image_base64 = event.image_base64
    newEvent.title = event.title_de || null
    newEvent.catchphrase = event.catchphrase_de
    newEvent.time_start = event.time_start
      ? dateFormatterDE(
          new Date(event.time_start),
          'dddd, d"." mmmm yyyy, HH:MM'
        )
      : null
    newEvent.location = event.location || null
    newEvent.price = event.price || null
    newEvent.img_thumbnail = event.img_thumbnail || null
    newEvent.description = event.description_de || null
    return newEvent
  })

const eventToEN = events =>
  events.map(event => {
    const newEvent = { ...event }
    newEvent.image_base64 = event.image_base64
    newEvent.title = event.title_en || null
    newEvent.catchphrase = event.catchphrase_en || null
    newEvent.time_start = event.time_start
      ? dateFormatterEN(new Date(event.time_start), 'dddd, mmmm dS yyyy, HH:MM')
      : null
    newEvent.location = event.location || null
    newEvent.price = event.price || null
    newEvent.img_thumbnail = event.img_thumbnail || null
    newEvent.description = event.description_en || null
    return newEvent
  })

const announce_content = async (
  eventIDs,
  featuredIDs = [],
  customEntries = [],
  message,
  template_stack = 'html'
) => {
  // Functions kept inside in oder to cleary adjust the template
  const session = axios.create({
    baseURL: `${ownUrl()}/templates/${template_stack}`,
    validateStatus: () => true,
  })
  //

  let events = await getEventsByIDs(eventIDs, customEntries)

  // modify events such that they are displayed correctly
  events = events.map(event => {
    const newEvent = event
    newEvent.price = event.price ? amountFormatter(event.price) : null
    newEvent.time_short = event.time_start
      ? dateFormatterEN(new Date(event.time_start), 'dd"."mm"."yyyy, HH:MM')
      : null
    return newEvent
  })

  const featuredEvents = await getEventsByIDs(featuredIDs, customEntries)

  const elements = []

  elements.push(
    renderElement(session, 'logo', {
      logo: announce_logo,
      month: dateFormatterDE(new Date(), 'mmmm').toUpperCase(),
      day: dateFormatterDE(new Date(), 'dd'),
    })
  )

  if(message) {
    elements.push(
      renderElement(session, 'message', {
        message,
      })
    )
  }

  if (featuredIDs.length > 0) {
    elements.push(
      renderElement(session, 'featured', { events: featuredEvents })
    )
  }

  elements.push(renderElement(session, 'agenda', { events }))
  elements.push(
    renderElement(session, 'articles', {
      language: 'Deutsch',
      events: eventToDE(events),
    })
  )
  elements.push(
    renderElement(session, 'articles', {
      language: 'English',
      events: eventToEN(events),
    })
  )
  elements.push(renderElement(session, 'footer'))

  const rendered_elements = await Promise.all([...elements])

  let announce = ''
  rendered_elements.forEach(element => {
    announce += element
  })

  return announce
}

const announce_html_css = async (
  eventIDs,
  featuredIDs = [],
  customEntries = []
) => {
  const css_location = `${ownUrl()}/templates/stylesheet.css`
  const css = (await default_session.get(css_location)).data

  const announce = await announce_content(eventIDs, featuredIDs, customEntries)

  const finished_announce = await renderElement(
    default_session,
    'html/header',
    {
      announce,
    }
  )

  const raw_files = [finished_announce, css]

  return raw_files
}

const announce_complete = async (
  eventIDs,
  featuredIDs = [],
  customEntries = [],
  message
) => {
  const css_location = `${ownUrl()}/templates/stylesheet.css`
  const css = (await default_session.get(css_location)).data

  const announce = await announce_content(eventIDs, featuredIDs, customEntries)

  const finished_announce = await renderElement(
    default_session,
    'html/header',
    {
      announce,
      message,
      css,
      css_location,
    }
  )

  return finished_announce
}

const announce_txt = async (eventIDs, featuredIDs = [], customEntries = [], message) => {
  return announce_content(eventIDs, featuredIDs, customEntries, message, 'txt')
}

export { announce_html_css, announce_complete, announce_txt }
export default announce_complete
