import axios from 'axios'
import { apiUrl } from '../config'

const session = axios.create({
  baseURL: apiUrl,
  headers: { 'Content-Type': 'application/json' },
  validateStatus: () => true,
})

const getEvents = async () => {
  const result = await session.get('events/')
  return result.data._items
}

const getEventsByIDs = async (ids = null, custom_entries = null) => {
  if (ids !== null || ids !== []) {
    return Promise.all(
      ids.map(id => {
        if (id.startsWith('custom_entry-')) {
          return new Promise((resolve, reject) => {
            const entry = custom_entries
              ? custom_entries.find(e => e._id === id)
              : null
            if (entry) {
              resolve({ data: { ...entry, _id: id } })
            } else {
              reject(new Error('not found in custom entries'))
            }
          })
        }
        return session.get(`events/${id}`)
      })
    ).then(res => res.map(event => event.data))
  }
  return null
}

const getAnnounceEventsBefore = async (date, page = 0) => {
  const isodate = `${date.toISOString().substr(0, 19)}Z`
  const query = {
    $and: [{ time_start: { $gte: `${isodate}` } }, { show_announce: true }],
  }
  const result = await session.get(
    `events?where=${JSON.stringify(query)}${
      page === 0 ? '' : `&page=${page}`
    }&sort=time_start`
  )

  return result.data
}

export default getEvents
export { getEvents, getEventsByIDs, getAnnounceEventsBefore }
