import { red } from '@material-ui/core/colors'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

export const color_red = '#e8462b'
export const color_dark_blue = '#1f2d54'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: color_dark_blue,
    },
    secondary: {
      main: color_red,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
})

const export_theme = responsiveFontSizes(theme)

export default export_theme
