import axios from 'axios'
import { loginApiUrl, oAuthID } from '../config'
import ownurl from './own_url'
import { set, get } from '../components/newComponents/localStorage'

export const login = () => {
  window.location.replace(
    `${loginApiUrl}/oauth?client_id=${oAuthID}&response_type=token&redirect_uri=${ownurl()}`
  )
}

export const checklogin = async () => {
  const token = get('token')
  if (!token) {
    return false
  }
  const session = axios.create({
    baseURL: loginApiUrl,
    headers: { 'Content-Type': 'application/json', Authorization: token },
    validateStatus: () => true,
  })

  const result = await session.get('sessions/')
  return result.status === 200
}

export const logout = () => {
  set('token', '')
}
