import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import { navigate } from '@reach/router'
import Button from '@material-ui/core/Button'
import { menuItems } from './menu'
import { logo } from '../../graphics'
import { login, checklogin, logout } from '../../utils/login'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  }
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={event => {
        event.preventDefault()
      }}
      {...props}
    />
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}))

export default function Header() {
  const classes = useStyles()
  const [value, setValue] = useState(0)

  const [isloggedin, setisloggedin] = useState(false)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleLogout = () => {
    logout()
    setisloggedin(false)
  }

  useEffect(() => {
    const check = async () => {
      const res = await checklogin()
      setisloggedin(res)
    }
    check()
  }, [])

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <img src={logo} alt="amiv_logo" />
          <Typography
            variant="h6"
            className={classes.title}
            style={{ marginRight: '2rem' }}
          >
            Announce-Tool
          </Typography>
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={handleChange}
            aria-label="nav tabs example"
            style={{ width: '75%' }}
          >
            {menuItems.map((tab, i) => (
              <LinkTab
                disabled={tab.needslogin === true && !isloggedin}
                key={i}
                label={tab.label}
                onClick={() => navigate(tab.path)}
                {...a11yProps(i)}
              />
            ))}
          </Tabs>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              isloggedin ? handleLogout() : login()
            }}
          >
            {isloggedin ? 'LOGOUT' : 'LOGIN'}
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  )
}
