import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { DropzoneArea } from 'material-ui-dropzone'
import DateFnsUtils from '@date-io/date-fns'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import axios from 'axios'

import { announceApiUrl } from '../../config'
import { get } from '../newComponents/localStorage'

const resetForm = () => {
  return {
    title_en: null,
    title_de: null,
    description_en: null,
    description_de: null,
    catchphrase_en: null,
    catchphrase_de: null,
    time_start: null,
    location: null,
    price: null,
    signup: null,
    image_base64: null,
  }
}

const FieldFormDialog = ({ open, handleClose, handleSubmit, values }) => {
  const [field, setField] = useState(values || resetForm())

  useEffect(() => {
    if (values){
      setField(values)
    }
  }, [values])

  const updateField = (field_pos, value) => {
    const new_field = JSON.parse(JSON.stringify(field))
    new_field[field_pos] = value
    setField(new_field)
  }

  const onChangeHandler = async files => {
    const session = axios.create({
      baseURL: announceApiUrl,
      validateStatus: () => true,
    })

    const formData = new FormData()
    formData.append('token', get('token'))
    formData.append('file', files[0])

    const result = await session.post('/upload', formData)

    updateField(
      'image_base64',
      announceApiUrl + '/upload/' + result.data.filename
    )
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Create custom entry</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please Enter the needed details for an announce entry.
        </DialogContentText>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <DropzoneArea
              onChange={onChangeHandler}
              filesLimit={1}
              acceptedFiles={['image/*']}
              maxFileSize={100000}
              showFileNames
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              placeholder="Title"
              margin="dense"
              id="title_en"
              label="English title"
              type="text"
              value={field.title_en}
              onChange={e => updateField('title_en', e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              placeholder="Titel"
              margin="dense"
              id="title_de"
              value={field.title_de}
              label="Deutscher Titel"
              type="text"
              onChange={e => updateField('title_de', e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              placeholder="Catchphrase"
              disabled={field.title_en == null}
              margin="dense"
              id="catchprase"
              label="English catchphrase"
              value={field.catchphrase_en}
              type="text"
              onChange={e => updateField('catchphrase_en', e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              placeholder="Catchphrase"
              disabled={field.title_de == null}
              margin="dense"
              value={field.catchphrase_de}
              id="catchprase"
              label="Deutsche Catchphrase"
              type="text"
              onChange={e => updateField('catchphrase_de', e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              placeholder="Description"
              disabled={field.title_en == null}
              margin="dense"
              id="description_en"
              value={field.description_en}
              label="English description"
              type="text"
              onChange={e => updateField('description_en', e.target.value)}
              fullWidth
              multiline
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              placeholder="Description"
              disabled={field.title_de == null}
              margin="dense"
              id="description_de"
              value={field.description_de}
              label="Deutsche Beschreibung"
              type="text"
              onChange={e => updateField('description_de', e.target.value)}
              fullWidth
              multiline
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          Additional Fields
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DateTimePicker
                variant="inline"
                label="Start time"
                value={field.time_start}
                onChange={date => updateField('time_start', date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              placeholder={1000}
              disabled={field.title_en == null && field.title_de == null}
              margin="dense"
              value={field.price}
              id="price"
              label="Price in Rappen"
              type="number"
              onChange={e => updateField('price', e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              placeholder="Bern"
              disabled={field.title_en == null && field.title_de == null}
              margin="dense"
              value={field.location}
              id="location"
              label="Location"
              type="text"
              onChange={e => updateField('location', e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField
              placeholder="Signup"
              disabled={field.title_en == null && field.title_de == null}
              margin="dense"
              id="signup"
              value={field.signup}
              label="Signup"
              type="text"
              onChange={e => updateField('signup', e.target.value)}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => {
            setField(resetForm())
            handleSubmit(field)
          }}
          color="primary"
          variant="contained"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

FieldFormDialog.propTypes = {
  /* Event provided as is from AMIV-API */
  open: PropTypes.bool,
  handleClickOpen: PropTypes.func,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
}

export default FieldFormDialog
