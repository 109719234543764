import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider } from '@material-ui/core/styles'
import { Router, Redirect } from '@reach/router'
import { menuItems } from './components/header/menu'
import Layout from './components/layout'
import theme from './theme'
import { set } from './components/newComponents/localStorage'

const urlParams = new URLSearchParams(window.location.search)
const token = urlParams.get('access_token')
if (token) {
  set('token', token)
}

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <Layout>
      <Router>
        <Redirect default from="test" to="selection" noThrow />
        {menuItems.map((item, i) => (
          <item.component key={i} path={item.path} />
        ))}
        {/* Error hadling <NotFound default /> */}
      </Router>
    </Layout>
  </ThemeProvider>,
  document.querySelector('#root')
)
