import React, { useEffect, useState } from 'react'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { saveAs } from 'file-saver'
import { Button } from '@material-ui/core'
import prep_announce from '../utils/announce_prepare'
import { color_dark_blue } from '../theme'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(8, 0, 3),
    alignContent: 'center',
  },
}))

const Preview = () => {
  // TODO: anmeldungslink
  const [mustacheRender, updateMustacheRender] = useState(
    'Mustache Render incoming'
  )
  const [mustacheTxt, updateMustacheTxt] = useState('Mustache Txt incoming')
  const [isLoading, setIsLoading] = useState()

  useEffect(() => {
    const renderMustache = async () => {
      setIsLoading(true)

      await prep_announce(updateMustacheRender, updateMustacheTxt)

      setIsLoading(false)
    }
    renderMustache()
  }, [])

  const clickHtmlDownload = () => {
    const blob = new Blob([mustacheRender], {
      type: 'text/plain;charset=utf-8',
    })
    saveAs(blob, 'announce.html')
  }
  const clickTxtDownload = () => {
    const blob = new Blob([mustacheTxt], {
      type: 'text/plain;charset=utf-8',
    })
    saveAs(blob, 'announce.txt')
  }

  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <h1 style={{ color: color_dark_blue }}>Preview</h1>
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <Container dangerouslySetInnerHTML={{ __html: mustacheRender }} />
      )}
      <Button variant="contained" color="primary" onClick={clickTxtDownload}>
        Download .txt
      </Button>
      <Button variant="contained" color="primary" onClick={clickHtmlDownload}>
        Download HTML
      </Button>
    </Container>
  )
}

export default Preview
