import React, { useState, useEffect } from 'react'
import Alert from '@material-ui/lab/Alert'
import Container from '@material-ui/core/Container'
import { getEventsByIDs } from '../utils/retrieveEvents'
import EventDNDList from '../components/list/eventDNDList'
import { get, set } from '../components/newComponents/localStorage'

const Sorting = () => {
  const [items, updateItems] = useState([])
  const [featured, updateFeatured] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    const fetchEvents = async () => {
      setIsLoading(true)
      const selected = get('selected') ? get('selected').split(',') : null
      const customEntries = get('custom_entries')
        ? JSON.parse(get('custom_entries'))
        : null

      if (selected != null) {
        const _events = await getEventsByIDs(selected, customEntries)

        updateItems(
          _events
            ? _events.map(item => ({
                id: item._id,
                title: item.title_en ? item.title_en : item.title_de,
                date: item.time_start,
                image_url: item.img_thumbnail ? item.img_thumbnail.file : null,
                image_base64: item.image_base64 || null,
              }))
            : []
        )
      }
      setIsLoading(false)
    }
    fetchEvents()
  }, [])

  useEffect(() => {
    set('order', JSON.stringify(items.map(item => item.id)))
  }, [items])

  useEffect(() => {
    set('featured', JSON.stringify(featured))
  }, [featured])

  const reorder = newOrder => {
    updateItems(newOrder)
  }

  const onSelected = selectedID => {
    const selected_clone = [...featured]
    selected_clone.push(selectedID)
    updateFeatured(selected_clone)
  }

  const onUnSelected = unselectedID => {
    const selected_clone = [...featured]
    updateFeatured(selected_clone.filter(_id => _id !== unselectedID))
  }

  return (
    <Container>
      <h1>Sorting</h1>
      <div style={{ marginBottom: '1rem' }}>
        Define order of events in the announce.
      </div>
      {featured.length > 2 ? (
        <Alert severity="error">
          You selected too many Items for featured in Announce
        </Alert>
      ) : (
        ''
      )}
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <EventDNDList
          items={items}
          onReordered={reorder}
          onUnSelected={onUnSelected}
          onSelected={onSelected}
        />
      )}
    </Container>
  )
}

export default Sorting
